import React, {Component} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loader from './Loader';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            streams: []
        }
    }

    async componentDidMount() {
        this.loadData();
        this.interval = setInterval(() => {
            this.loadData();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getTwitchURL(channel) {
        return 'https://player.twitch.tv/?channel=' + channel + '&muted=true&autoplay=true';
    }

    async loadData() {
        let url = process.env.REACT_APP_COCKPIT_URL + '/api/collections/get/streams?sort[is_featured]=-1';
        let response = await fetch(url);
        if (response.ok) {
            let result = await response.json();
            this.setState({loading: false, streams: result.entries})
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <Loader/>
            );
        }

        if (this.state.streams.length === 0) {
            return (<div/>);
        }

        if (this.state.streams[0].is_featured) {
            let url = this.getTwitchURL(this.state.streams[0].channel);

            return (
                <iframe title={this.state.streams[0].channel} className="w-100 vh-100 border-0" src={url}/>
            );
        }

        return (
            <Row className="vh-100">
                {this.state.streams.map((stream, index) => {
                    let url = this.getTwitchURL(stream.channel);

                    return (
                        <Col lg={4} key={index} className={'stream ' + stream.is_featured ? 'featured' : 'regular'}>
                            <div className="text-center w-100 mt-1">{stream.place}</div>
                            <iframe className="w-100 h-75 border-0" src={url} title={stream.channel}/>
                        </Col>
                    );
                })}
            </Row>
        );

    }
}

export default List;