import React, {Component} from 'react';
import 'bootstrap/scss/bootstrap.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Ticker from './Ticker'
import List from './List'

class App extends Component {
    render() {
        return (
            <div className="app vh-100">
                <Row>
                    <Col lg={9}>
                        <List/>
                    </Col>
                    <Col lg={3}>
                        <Ticker/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default App;
